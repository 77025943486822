@use '@iris/styles/src/scss/variables' as *;
@use '@iris/styles/src/scss/colors' as *;
@use '@iris/styles/src/scss/mixins' as *;

@use '../core/core';

$params:();
$row-height: 40px;
$cell-horizontal-padding: 5px;

.ag-theme-iris {
  .ng-select {
    @include core.ng-select-customization($params);

    display: flex;
    flex-grow: 1;
    min-width: 0;

    .ng-select-container {
      .ng-arrow-wrapper {
        position: relative;
      }
    }

    .ng-spinner-loader {
      margin-bottom: 0;
      margin-right: 0;
    }

    .ng-select-container .ng-clear-wrapper {
      z-index: 2;
    }
    .ng-select-container .ng-clear-wrapper,
    .ng-select-container .ng-arrow-wrapper {
      width: unset;
      margin-left: $cell-horizontal-padding;
    }

    &.ng-select-single:not(.df-selection-property-selector):not(.pagination-selector) {
      &.ng-select-focused {
        div[role="combobox"].ng-input {
          right: 0;
        }
        .ng-select-container:not(.ng-has-value)
        div[role="combobox"].ng-input {
          position: relative;
        }
      }
      &.ng-select-focused.ng-select-opened {
        .ng-value {
          display: none;
        }
      }
    }

    &.pagination-selector .ng-select-container .ng-value-container .ng-value-label {
      font-size: $size-medium;
    }
  }

  .ag-popup-editor {
    max-width: 50%;
    .ng-select {
      min-width: 208px;
      max-width: 280px;

      &:not(.ng-select-multiple) {
        .ng-select-container {
          .ng-value-container {
            .ng-placeholder {
              top: 1rem;
            }
          }
        }
      }
    }
  }

  df-floating-filter-generic {
    .ng-select {
      .user-as-chip {
        &.with-avatar {
          margin-left: 0;
        }
      }
    }
  }

  .iris-select-input-container {
    margin: 0 #{-$cell-horizontal-padding};

    .ng-select {
      height: $row-height;

      .ng-select-container {
        align-items: center;
        padding: 0;
        .ng-value {
          display: flex;
          align-items: center;
          margin: 0 0 0 $cell-horizontal-padding;
        }

        .ng-value-container {
          z-index: 1;
        }

        .ng-clear-wrapper {
          display: none;
          margin-left: 0;
        }
      }

      .ng-select-container {
        .ng-value-container {
          position: relative;

          .ng-input {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 0 $cell-horizontal-padding;
            display: flex;
            align-items: center;
          }
        }
      }

      &.ng-select-opened,
      &:hover {
        .ng-select-container {
          .ng-input {
            right: 0;
          }

          .ng-clear-wrapper {
            display: flex;
          }
        }
      }
    }
  }

  ng-select:not(.df-selection-property-selector) {
    margin: 0 #{-$cell-horizontal-padding};
    padding: 0 $cell-horizontal-padding;

    .ng-dropdown-panel {
      &.ng-select-bottom {
        transform: translateY(1px);
      }

      &.ng-select-top {
        transform: translateY(-1px);
      }

      .ng-option {
        display: flex;
        align-items: center;
      }
    }

    .ng-select-container {
      min-height: unset;
      align-items: center;

      &::after {
        border-bottom-color: transparent !important;
      }

      .ng-value-container {
        min-height: unset;
        z-index: 1;

        .ng-input {
          top: 0;
          bottom: 0;
        }
      }

      .ng-input {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .ng-clear-wrapper {
        display: none;
      }

      .ng-value-container,
      .ng-arrow-wrapper {
        padding: 0 !important;
        border: none !important;
      }
    }

    &.ng-select-single {
      .ng-select-container {
        .ng-arrow-wrapper {
          bottom: 0;
        }
      }
    }

    &.ng-select-opened,
    &:hover {
      .ng-select-container {
        .ng-clear-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .ng-input {
          right: 1.5rem;
        }
      }
    }
  }

  .iris-field-prefix-hint {
    display: none;
  }
}

.ng-dropdown-panel {
  &:not(.dropdown-width-native) {
    min-width: $dropdown-width !important;

    & .ng-dropdown-header,
    & .ng-dropdown-footer,
    & .ng-dropdown-panel-items {
      min-width: inherit;
    }
  
    &.dropdown-width-auto,
    .ng-select.dropdown-width-auto & {
      width: auto !important;
      min-width: auto !important;
    }
  }

  &.pagination-selector {
    @include core.ng-select-dropdown($params: (
      ng-select-dropdown-bottom-shift: -1.2em,
    ));
  }
}

@use '@iris/styles/src/scss/variables' as *;
@use '@iris/styles/src/scss/colors' as *;

$ng-select-highlight: rgba(($primary-blue), 0.87) !default;
$ng-select-primary-text: $secondary-grey-600 !default;
$ng-select-primary-light-text: rgba($white, 0.87) !default;
$ng-select-secondary-text: $secondary-grey-400 !default;
$ng-select-secondary-light-text: rgba($white, 0.54) !default;
$ng-select-disabled-text: rgba(0, 0, 0, 0.38) !default;
$ng-select-divider: rgba($secondary-grey-600, 0.24) !default;
$ng-select-bg: $white !default;
$ng-select-clear-icon-size: 1.75rem;
$ng-select-label-top-distance: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.001px);
$ng-select-container-height: 44px !default;
$ng-select-appearance-height: 61.25px !default;
$ng-select-dropdown-top-shift: -1.25em !default;
$ng-select-dropdown-bottom-shift: 1em !default;

$ng-select-inline-params: (
  ng-select-bottom-spacer: 0,
  ng-select-bottom-line-color: $ng-select-divider,
  ng-select-focused-color: $ng-select-highlight,
  ng-select-show-label: none,
  ng-select-container-height: $size-normal * 2,
  ng-select-width: auto,
  ng-select-inline-min-width: $dropdown-width * 0.3,
  ng-select-dropdown-top-shift: 0,
  ng-select-dropdown-bottom-shift: 0,
);

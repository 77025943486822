@use '../core/core';

.ng-select-no-label, 
[floatLabel*=none],
[floatLabel*=never], 
[no-label] {
  @include core.ng-select-customization(
    $params: (
      ng-select-show-label: none
    )
  );
  &.user-select-field {
    .ng-value-container {
      @include core.ng-select-no-label;
    }
  }
}
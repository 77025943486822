@use '@iris/styles/src/scss/variables' as *;
@use '@iris/styles/src/scss/colors' as *;
@use '@iris/styles/src/scss/mixins' as *;

@use 'vars';

@mixin ng-value-common {
  font-size: $size-rich;
  font-weight: 400;
}

@mixin ng-select-no-label {
  border: none;
  padding-top: 0;
  min-height: initial;
}

@mixin ng-select-core {
  .ng-select-container {
    align-items: flex-end;

    &::after {
      border-bottom-width: thin;
      border-bottom-style: solid;
      content: '';
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
      transition: border-color .3s cubic-bezier(.55, 0, .55, .2);
    }

    .ng-value-label {
      display: flex;
      min-width: 0;

      span {
        @include text-ellipsis;
      }
    }

    .ng-clear-wrapper,
    .ng-arrow-wrapper {
      width: 1.75rem;
      line-height: 1;
    }

    &.ng-appearance-outline {
      padding: 0 .5em;

      &:after {
        border: solid 1px vars.$ng-select-divider;
        border-radius: $radius * 1.25;
        height: calc(100% - .5em);
        pointer-events: none;
        transition: border-color .3s cubic-bezier(.25, .8, .25, 1);

        &:hover {
          border-color: vars.$ng-select-primary-text;
          border-width: $radius * 0.5;
        }
      }

      .ng-placeholder {
        padding: 0 .25em;
        background-color: vars.$ng-select-bg;
        z-index: 1;
      }

      .ng-value {
        padding-left: .25em;
      }

      .ng-clear-wrapper {
        width: 1.75em;
      }
    }

    .ng-value-container {
      align-items: stretch;
      padding-top: 0.475rem;
      padding-bottom: 0;
      border-top: $size-small solid transparent;

      .ng-placeholder {
        top: 1.15rem;
        position: absolute;
        color: vars.$ng-select-secondary-text;
        transform-origin: left 0;
        transition: transform .4s cubic-bezier(.25, .8, .25, 1), color .4s cubic-bezier(.25, .8, .25, 1), width .4s cubic-bezier(.25, .8, .25, 1);
        right: 1.5rem;
        left: 0;
        @include ng-value-common;
        @include text-ellipsis;
      }

      .ng-value-label {
        color: vars.$ng-select-primary-text;
        @include ng-value-common;
      }

      .ng-input {
        bottom: .24em;
        line-height: 1.5;
        &, & input {
          color: vars.$ng-select-primary-text;
          @include ng-value-common;
        }
      }

      .ng-placeholder, .ng-value-label, .ng-input input {
        -webkit-font-smoothing: antialiased;
      }
    }

    .ng-clear-wrapper {
      text-align: center;

      .ng-clear {
        font-weight: 300;
        text-align: center;
        color: $grey-300;
        font-size: vars.$ng-select-clear-icon-size;
      }
    }

    .ng-arrow-wrapper {
      right: 0;
      bottom: 0;
      padding-left: 3px;
      position: absolute;
      padding-right: 3px;
      padding-bottom: 6px;

      .ng-arrow {
        margin: 0 0.3em $gap * 0.5;
        border-left: 0.3em solid transparent;
        border-right: 0.3em solid transparent;
        border-top: 0.3em solid;
        color: $grey-300;
      }
    }

    .ng-spinner-loader {
      margin-right: 2rem;
      margin-bottom: 0.3rem;
      border-top-color: $grey-250-disabled;
      border-right-color: $grey-250-disabled;
      border-bottom-color: $grey-250-disabled;
      border-left-color: $grey-150-borders;
    }

    .ng-spinner-loader + .ng-clear-wrapper {
      margin-left: -2rem;
    }
  }

  &:not(.not-autocomplete) .ng-has-value,
  &.ng-select-opened.ng-select-filtered {
    .ng-arrow-wrapper {
      display: none;
    }
  }
  &.ng-select-disabled {
    .ng-select-container {
      cursor: not-allowed;
      &:after {
        border-bottom-color: transparent;
        background-image: linear-gradient(to right, rgba($secondary-grey-600, 0.24) 0%, rgba($secondary-grey-600, 0.24) 33%, transparent 0%);
        background-size: 4px 1px;
        background-repeat: repeat-x;
      }

      .ng-value-container {
        &, .ng-value, .ng-value-label, .ng-input input, .ng-placeholder {
          color: vars.$ng-select-disabled-text;
        }
      }
    }

    .ng-arrow-wrapper .ng-arrow,
    .ng-clear-wrapper {
      display: none;
    }

    .ng-spinner-loader {
      margin-right: 0;
    }
  }

  &.ng-select-focused {
    .ng-select-container {
      caret-color: vars.$ng-select-highlight;
      &::after {
        border-width: $radius * 0.5;
        border-color: vars.$ng-select-highlight;
      }

      &.ng-appearance-outline {

        &:after,
        &:hover:after {
          border-color: vars.$ng-select-highlight;
          border-width: $radius * 0.5;
        }
      }

      .ng-value-container .ng-placeholder {
        transform: vars.$ng-select-label-top-distance;
        color: vars.$ng-select-highlight;
      }

      .ng-arrow-wrapper .ng-arrow {
        color: vars.$ng-select-highlight;
      }
    }
  }
  
  &.ng-select-opened .ng-select-container,
    .ng-select-container.ng-has-value {
      .ng-placeholder {
        top: 1.4rem;
        font-size: 1rem;
      }
    }

  .ng-has-value,
  &.ng-select-opened {
    .ng-placeholder {
      transform: vars.$ng-select-label-top-distance;
    }
  }

  &.ng-select-single {
    .ng-select-container {
      &.ng-appearance-outline {
        .ng-arrow-wrapper {
          bottom: 17px;
        }

        .ng-clear-wrapper {
          bottom: 14px;
        }
      }

      .ng-value-container {
        .ng-input {
          width: auto;
          right: 1.5rem;
        }
      }
    }
  }

  &.ng-select-multiple {
    &.ng-select-focused {
      .ng-value-container .ng-input {
        margin-bottom: 0.3rem;
      }
    }
    &.ng-select-disabled {
      .ng-select-container .ng-value-container .ng-value {
        background-color: $grey-100;
        color: $grey-250-disabled;
        padding-right: $size-medium;
      }

      .ng-appearance-outline {

        &:after,
        &:hover:after {
          background-image: none;
          border: dotted 1px vars.$ng-select-divider;
        }
      }
    }

    .ng-select-container {
      &.ng-appearance-outline {
        &.ng-has-value {

          .ng-arrow-wrapper,
          .ng-clear-wrapper {
            border-top: none;
          }
        }

        .ng-arrow-wrapper {
          top: 0;
        }

        .ng-clear-wrapper {
          top: $gap;
        }
      }

      .ng-value-container {
        .ng-value {
          margin: $gap;
          display: flex;
          line-height: 1.25;
          align-items: center;
          color: vars.$ng-select-primary-text;
          border-radius: $radius * 4;
          flex-direction: row-reverse;
          padding: 0.25rem 0.5rem;
          padding-left: $gap-3x;
          background-color: rgba($secondary-grey-400, 0.22);
          max-width: calc(100% - #{$gap-2x});

          &:hover {
            background-color: rgba($secondary-grey-400, 0.47);
          }

          &.ng-value-disabled {
            color: rgba($secondary-grey-600, .26);
            background-color: vars.$ng-select-divider;
          }

          .ng-value-label {
            @include ng-value-common;
          }

          .ng-value-icon {
            font-weight: 600;
            flex-shrink: 0;
            border-radius: 50%;
            text-align: center;
            width: $size-normal;
            color: $grey-150-borders;
            height: $size-normal;
            display: inline-block;
            font-size: $size-rich;
            line-height: $size-normal;
            margin-left: $size-normal * 0.5;
            background-color: rgba($secondary-grey-600, 0.34);
          }
        }

        .ng-input {
          @include ng-value-common;
          bottom: initial;
          align-self: flex-end;
          padding-top: $gap * 0.5;
          height: $size-rich * 1.5;
          margin-right: $size-rich * 2;
        }
      }

      &.ng-has-value {

        .ng-placeholder {
          top: 1.125rem;
        }

        .ng-value-container {
          padding-bottom: 0;
          padding-top: 0;
          width: calc(100% - 24px);
        }
      }
    }
  }

  .ng-select-wrapper {
    .ng-clear-wrapper {
      right: $iris-spacer;
      bottom: 0;
      z-index: 2;
      width: initial;
      position: absolute;
      color: $grey-300;

      &:hover {
        color: vars.$ng-select-primary-text;
      }
    }
  }

  .ng-spinner-zone {
    top: 3px;
  }

  &[required] {
    .ng-select-container {
      .ng-value-container {
        .ng-placeholder {
          &:after {
            content: ' *';
            letter-spacing: -3px;
            color: $error;
          }
        }
      }
    }
  }

  &.ng-invalid.ng-touched {
    .ng-select-container {
      &:after {
        border-color: $error;
      }

      .ng-placeholder {
        color: $error;
      }

      .ng-arrow-wrapper {
        .ng-arrow {
          color: $error;
        }
      }
    }
  }
}

@mixin ng-select-dropdown($params) {
  left: 0;
  font-size: $size-rich;
  background: vars.$ng-select-bg;
  min-width: map-get($params, ng-select-dropdown-min-width) !important;

  &.ng-select-bottom {
    top: 100%;
    transform: translateY(map-get($params, ng-select-dropdown-top-shift));
    box-shadow: 0 2px 4px -1px rgba($secondary-grey-600, .2), 0 4px 5px 0 rgba($secondary-grey-600, .14), 0 2px 10px 0 rgba($secondary-grey-600, .12);
  }

  &.ng-select-top {
    bottom: 100%;
    transform: translateY(map-get($params, ng-select-dropdown-bottom-shift));
    box-shadow: 0 -5px 5px -3px rgba($secondary-grey-600, .2), 0 -8px 10px 1px rgba($secondary-grey-600, .14), 0 -3px 14px 2px vars.$ng-select-divider;
  }

  &.multiple {
    .ng-option {
      &.selected {
        background: vars.$ng-select-bg;
      }

      &.marked {
        background: rgba($secondary-grey-600, .04);
      }
    }
  }

  .ng-dropdown-header {
    border-bottom: 1px solid vars.$ng-select-divider;
    padding: 0 16px;
    line-height: 3em;
    min-height: 3em;
  }

  .ng-dropdown-footer {
    border-top: 1px solid vars.$ng-select-divider;
    padding: 0 16px;
    line-height: 3em;
    min-height: 3em;
  }

  .ng-dropdown-panel-items {
    .ng-optgroup {
      user-select: none;
      cursor: pointer;
      line-height: 3em;
      height: 3em;
      padding: 0 16px;
      color: vars.$ng-select-secondary-text;
      font-weight: 500;

      &.ng-option-marked {
        background: rgba($secondary-grey-600, .04);
      }

      &.ng-option-disabled {
        cursor: default;
      }

      &.ng-option-selected {
        background: vars.$ng-select-divider;
        color: $white;
      }
    }

    .ng-option {
      line-height: 3.4286em;
      min-height: 3.4286em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 16px;
      text-decoration: none;
      position: relative;
      color: vars.$ng-select-primary-text;
      text-align: left;

      &.ng-option-marked {
        background: rgba($secondary-grey-600, .04);
        color: vars.$ng-select-primary-text;
      }

      &.ng-option-selected {
        background: vars.$ng-select-divider;
        color: $white;
      }

      &.ng-option-disabled {
        color: vars.$ng-select-disabled-text;
      }

      &.ng-option-child {
        padding-left: 32px;
      }

      .ng-tag-label {
        padding-right: 5px;
        font-weight: 400;
        color: vars.$ng-select-highlight;
      }
    }
  }

  .subline {
    font-size: $size-medium;
    color: $grey-300;
  }
  
  .normalize-line-height {
    line-height: 2;
    &.subline {
      line-height: 1;
    }
  }
}

@mixin ng-select-customization($params) {
  padding-bottom: map-get($params, ng-select-bottom-spacer);
  width: map-get($params, ng-select-width);

  .ng-has-value,
  &.ng-select-focused,
  &.ng-select-opened,
  &.ng-select-filtered .ng-select-container {
    .ng-placeholder {
      display: map-get($params, ng-select-show-label);
    }
  }

  .ng-select-container {
    min-height: map-get($params, ng-select-container-height);

    &::after {
      border-color: map-get($params, ng-select-bottom-line-color);
    }

    &.ng-appearance-outline {
      padding: 0 .5em;
      min-height: map-get($params, ng-select-appearance-height);
    }

    .ng-value-container {
      min-height: map-get($params, ng-select-container-height);
    }
  }

  &.ng-select-focused {
    .ng-select-container {
      &::after {
        border-color: map-get($params, ng-select-focused-color);
      }
    }
  }
}

@mixin ng-select-inline($params: vars.$ng-select-inline-params) {
  @include ng-select-customization($params);
  display: inline-block;
  min-width: map-get($params, ng-select-inline-min-width);
  &.ng-select-single {
    .ng-select-container {
      align-items: center;

      .ng-value-container {
        padding: 0;
        border: none;
        padding-left: $gap-2x;
        min-height: map-get($params, ng-select-container-height);

        .ng-placeholder,
        .ng-input {
          top: 0;
          bottom: 0;
          right: 2rem;
          left: $gap-2x !important;
          width: auto !important;
        }

        .ng-placeholder,
        .ng-input,
        .ng-value {
          line-height: map-get($params, ng-select-container-height);
        }

      }
    }
  }

  .ng-has-value,
  &.ng-select-opened {
    .ng-placeholder {
      transform: none;
    }
  }
}

@use '../core/core';
@use '../core/vars';

ng-select.ng-select.ng-select-inline {
  @include core.ng-select-inline();
}
ng-dropdown-panel {
  &.ng-select-inline, .ng-select-inline & {
    @include core.ng-select-dropdown(vars.$ng-select-inline-params);
  }
}

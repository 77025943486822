@use '@iris/styles/src/scss/variables' as *;
@use '@iris/styles/src/scss/colors' as *;
@use '@iris/styles/src/scss/mixins' as *;

@use '../core/core';

@mixin ng-value-as-chip($ng-select-divider) {
  margin: $gap;
  display: flex;
  line-height: 2;
  align-items: center;
  color: rgba($secondary-grey-600, 0.87);
  border-radius: $radius * 4;
  flex-direction: row-reverse;
  background-color: rgba($secondary-grey-400, 0.22);

  &:hover {
    background-color: rgba($secondary-grey-400, 0.47);
  }

  &.ng-value-disabled {
    color: rgba($secondary-grey-600, .26);
    background-color: $ng-select-divider;
  }

  .ng-value-label {
    @include core.ng-value-common();
  }

  .ng-value-icon {
    display: none;
  }
}

ng-select.ng-select-selected-as-chip {
  $ng-select-divider: rgba($secondary-grey-600, 0.24) !default;

  &.ng-select-single {
    .ng-select-container {
      .ng-value-container {
        .ng-value {
          @include ng-value-as-chip($ng-select-divider);
        }
      }
    }
  }

  .ng-select-container {
    &.ng-has-value {
      .ng-value-container {
        padding-bottom: 0;
      }
    }

    .ng-value-container {
      .ng-value {
        padding: $gap $gap-2x $gap $gap-3x !important;

        .position-relative,
        .ng-value-label {
          min-width: 0;
        }
      }

      .ng-input {
        margin-right: 0 !important;
      }
    }

    .ng-arrow-wrapper {
      position: relative !important;
    }
  }

  &.ng-select-focused,
  &.ng-select-opened {
    .ng-select-container {
      .ng-value-container {
        .ng-input {
          flex: 1 1 auto !important;
        }
      }
    }
  }
  &:not(.ng-select-filtered) {
    .ng-select-container:not(.ng-has-value) {
      .ng-spinner-loader {
        margin-right: 0;
      }
    }
  }
  &:not(.ng-select-focused) input,
  input[multiple='false'] {
    &::placeholder {
      color: transparent;
    }
  }
}

@use '../core/core';
@use '../core/vars';

$defaults: (
  ng-select-container-height: vars.$ng-select-container-height,
  ng-select-appearance-height: vars.$ng-select-appearance-height,
  ng-select-width: 100%,
  ng-select-bottom-line-color: vars.$ng-select-divider,
  ng-select-focused-color: vars.$ng-select-highlight,
  ng-select-bottom-spacer: 1.238rem,
  ng-select-show-label: initial,
  ng-select-dropdown-top-shift: vars.$ng-select-dropdown-top-shift,
  ng-select-dropdown-bottom-shift: vars.$ng-select-dropdown-bottom-shift,
  ng-select-dropdown-min-width: initial,
);
.ng-select {
  @include core.ng-select-core;
  @include core.ng-select-customization($defaults);
}

.ng-dropdown-panel {
  @include core.ng-select-dropdown($defaults);
}